import React, { useState, useEffect } from 'react';

import './App.css';
import life from './life.json';
import properties from './properties.json';

function App() {

  const [day, setDay] = useState(false);
  const [para, setPara] = useState(false);

  function timeout(delay) {
    return new Promise(res => setTimeout(res, delay));
  }

  async function selectedDay() {
    setPara(true);
    setDay(false);

    await timeout(5000); //for 1 sec delay

    setPara(false);
    setDay(true);
  }

  return (
    <>
      {!(day || para) && <div className="text-green-600 p-5">
        <div class=" text-[3px] md:text-[10px]" id="output"></div>
        <br></br>
        <p>QUERY&gt; @elonmusk</p>
        <p className="text-gray-300">
          {/* OUTPUT&gt; Step into the shoes of one of the most influential entrepreneurs of our time and experience a day in the life of Elon Musk. With over 16.777216 quintillion possible options, you'll embark on a journey that is uniquely yours, and yours alone. */}
          16.777216 quintillion possible simulations available.
        </p>
        <div>
          {!day && <button onClick={selectedDay} className="bg-green-700 px-2 text-white mr-3 mt-2 hover:bg-white hover:text-black text-[20px]">START SIMULATION  ↩</button>}
        </div>

        <br></br>
        {true &&
          <div>
            <p>QUERY&gt; SIMULATION PARAMETERS:</p>
            <span className="text-gray-300">&#123; </span>
            {
              Object.keys(properties).map((key, index) => {
                return (
                  <>
                    <span className="text-white"><span className="text-red-700">{key}</span > : <span className="text-gray-300">"{properties[key]}"{index+1!=Object.keys(properties).length && <span>,</span>} </span></span>
                  </>
                );
              })}
              {/* <p className="text-gray-300">OUTPUT&gt; {JSON.stringify(properties)}</p> */}
            <span className="text-gray-300">&#125;</span>
          </div>
        }
        <br></br>
      </div>
      }

      {(day || para) && <div className='overlay text-green-100 flex flex-col p-5'>
        {
          para && <div>
            <div class="text-[3px] md:text-[6px]" id="output2"></div>
            <br></br>
            <p>INITIALIZING SIMULATION...</p>
          </div>
        }

        {
          !para &&
          <div>
            <table>
              <tbody>
                {
                  life.map(function (object, i) {
                    return <tr>
                      {
                        i >= 10 && <td className="text-gray-300 pr-3 pt-1">{i}:00</td>
                      }
                      {
                        i < 10 && <td className="text-gray-300 pr-3 pt-1">0{i}:00</td>
                      }
                      <td>{object[Math.floor(Math.random() * 20)]}</td>
                    </tr>
                  }
                  )
                }
              </tbody>
            </table>
            <br></br>
            <p className='mb-2'><a href="https://twitter.com/intent/tweet?text=✷ an @elonmusk simulation ✷ by @notpinkxyz%0A%0AThere is a 1 in 16.777216 quintillion chance that I generated Elon Musks' day.%0A%0Aelonmusk.notpink.xyz" className='bg-white text-black px-2 py-2 hover:bg-black hover:text-white'>SHARE ON TWITTER ↩</a></p>
            <p className=''>by <a className='underline' target='_blank' href="https://notpink.xyz/">notpink.xyz</a></p>
          </div>
        }

      </div>
      }

    </>

  );
}

export default App;
